'use client';

import { ReactNode } from 'react';

import { SessionProvider } from 'next-auth/react';

export interface AuthLayoutProps {
  children?: ReactNode;
}

/**
 * A layout component for the login and logout page.
 */
export default function AuthLayout({ children }: Readonly<AuthLayoutProps>) {
  return <SessionProvider>{children}</SessionProvider>;
}
